import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Spinner from 'react-spinner-material'
import { Grid } from '@mui/material'
import Layout from '../components/layout'
import Seo from '../components/seo'

class LoadingPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {      
      loading: true,
    }    
    this.changeCookie = this.changeCookie.bind(this)
  }
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  componentDidMount() {    
    if (!this.props.allCookies.firstload) {
      this.changeCookie('firstload', true)      
      setTimeout(() => {
        navigate(this.props.allCookies.lastpage)
      }, 1000)
    }    
    this.setState({ loading: false })
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'EdgeForecaster Loading'
    var pagewidth = {
        matches: false,
        media: '(max-width: 600px)',
        onchange: null,
    }
    if (typeof window !== 'undefined') {
        pagewidth = window.matchMedia('(max-width: 600px)')
    }

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          {this.state.loading ? (
            <div className="spinneroverlay">
              <div className="spinnercontainer">
                <Spinner
                  radius={100}
                  color={'#4648ff'}
                  stroke={2}
                  visible={true}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>                
                <div style={{ minHeight: '40vmin' }}>                  
                </div>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(LoadingPage)

